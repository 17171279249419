
export default {
	name: 'orCrcParticulars',
	data() {
		return {
			sex: 'girl',
			newEdit: false,
			name: '',
			years: '',
			list: '',
			education: '',
			major: '',
			age: '',
			proCount: '',
			inCount: '',
			img: ''
		}
	},

	created() {
		this.token = this.getCookie('token')
		this.getCrcParticulars()
	},
	methods: {
		getCrcParticulars() {
			var _this = this;
			_this.$http.post('/API/PLAT/crcUserInfo', {
				id: this.$route.params.id
			}, {
				headers: { "token": _this.token }
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.list = response.data.data.proList;
						_this.name = response.data.data.name;
						_this.years = response.data.data.years;
						_this.education = response.data.data.education;
						_this.major = response.data.data.major;
						_this.age = response.data.data.age;
						_this.proCount = response.data.data.proCount;
						_this.inCount = response.data.data.inCount;
						_this.img = response.data.data.headImg
					}
				})
		},
		toCrcMena() {
			this.$router.push('/statistics')
		},
		toPro(id) {
			this.$router.push({ name: 'projectParticulars', params: { id: id } })
		}
	}
}
